var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AMeasureProcessStepContainer',{attrs:{"title":_vm.$ft(_vm.doc.title),"current":_vm.doc.state === 'wip' || _vm.doc.state === 'verify',"is-verifying":_vm.doc.state === 'verify',"attachments":_vm.doc.attachmentsCount,"comments":_vm.doc.commentsCount,"signature":_vm.doc.signature,"date":_vm.doc.dueDate,"user":_vm.doc.responsible}},[_c('div',{staticClass:"step-corrective-measure"},[(_vm.doc.state === 'new')?_c('div',{staticClass:"new"},[_c('v-textarea',{attrs:{"outlined":"","auto-grow":"","rows":"4","hide-details":"","label":_vm.$t(
            'components.controls.measure_process_steps.measure_process_step_corrective_measure.measure_description_label'
          )},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('div',{staticClass:"d-flex mt-4"},[_c('a-user-autocomplete',{staticClass:"mr-4",attrs:{"label":_vm.$t(
              'components.controls.measure_process_steps.measure_process_step_corrective_measure.responsible_employee_label'
            ),"hide-details":"","outlined":"","dense":""}}),_c('AMenuDatePicker',{attrs:{"outlined":true,"dense":true,"label":_vm.$t(
              'components.controls.measure_process_steps.measure_process_step_direct_measure.target_date_label'
            ),"return-string":true},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1):_vm._e(),(_vm.doc.state !== 'new')?_c('div',{staticClass:"finished"},[_vm._v(_vm._s(_vm.doc.text))]):_vm._e(),(_vm.doc.state !== 'new')?_c('v-list',{staticClass:"tasks"},[_vm._l((_vm.doc.tasks),function(task){return _c('v-list-item',{key:task.text},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"primary","value":task.done}})],1),_c('v-list-item-content',{class:{ done: task.done }},[_vm._v(" "+_vm._s(task.text)+" ")]),_c('v-list-item-action',[_c('a-user-chip',{attrs:{"user":task.responsible}})],1)],1)}),_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"primary","disabled":""}})],1),_c('v-list-item-content',[_c('v-text-field',{staticClass:"new-task-input",attrs:{"placeholder":_vm.$t(
                'components.controls.measure_process_steps.measure_process_step_corrective_measure.add_task_placeholder'
              ),"dense":"","hide-details":""}})],1),_c('v-list-item-action',[_c('a-user-chip',{attrs:{"user":null}})],1)],1)],2):_vm._e(),(_vm.remainingTasks > 0 && _vm.doc.state !== 'new')?_c('div',{staticClass:"px-4 mb-4 success--text"},[_vm._v(" "+_vm._s(_vm.remainingTasks !== 1 ? (_vm.remainingTasks + " Aufgaben") : (_vm.remainingTasks + " Aufgabe"))+" verbleibend ")]):_vm._e()],1),(!['done', 'verify'].includes(_vm.doc.state))?_c('template',{slot:"actions"},[(_vm.doc.state === 'new')?_c('v-btn',{attrs:{"text":"","color":"success darken-1"},on:{"click":function($event){return _vm.startStep(_vm.doc.id)}}},[_vm._v(" Starten ")]):_vm._e(),(_vm.doc.state === 'wip')?_c('v-btn',{attrs:{"text":""}},[_vm._v("Abschließen")]):_vm._e(),(_vm.doc.state === 'new')?_c('v-btn',{attrs:{"text":""}},[_vm._v("Überspringen")]):_vm._e()],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }