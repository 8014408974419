
















































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import { api } from "@/store/modules/measureProcess";

import { MeasureProcessStepCorrectiveMeasure } from "@/store/modules/measureProcess/types";
import AUserChip from "@/components/snippets/AUserChip.vue";
import ADateChip from "@auditcloud/components/snippets/ADateChip.vue";

@Component({
  components: {
    AUserChip,
    ADateChip,
  },
})
export default class AMeasureProcessStepCorrectiveMeasure extends Vue {
  date = null;
  datePickerVisible = false;

  @Prop({
    type: MeasureProcessStepCorrectiveMeasure,
  })
  readonly doc!: MeasureProcessStepCorrectiveMeasure;

  get remainingTasks() {
    return this.doc.tasks.filter(t => !t.done).length;
  }

  @Action(api.actions.startStep, {
    namespace: api.namespace,
  })
  startStep!: (payload: any) => Promise<void>;
}
